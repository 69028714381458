import {Login} from "@wradetech/react-components";

const LandingPage = () => {
    
    return (
        <>
            <Login text="CRM Platform" title="Brey Hub"/>
        </>

    )
}

export default LandingPage;
