import {CreateCompanyForm} from "../components/Company/CreateCompanyForm";
import {Company} from "../global";
import {toast} from "react-toastify";
import {ApiClient} from "../services/api";

export const Dashboard = () => {
    const onCompanyCreated = async (company: Company) => {
        toast.info(`${company.name} created`);

        const client = new ApiClient();

        await client.post('company', company)
    };
    
    
    return <>
        <CreateCompanyForm onSubmitted={onCompanyCreated}/>
    </>
}