import {useState} from "react";
import {Company} from "../../global";
import {Button, Card, Textbox} from "@wradetech/react-components";

interface CreateCompanyProps {

    onSubmitted: (company: Company) => void;
}

export const CreateCompanyForm = (props: CreateCompanyProps) => {
    
    // @ts-ignore
    const [company, setCompany] = useState<Company>({});
    
    const handleTextChange = (e: any) => {
        setCompany({
            ...company,
            [e.target.name]: e.target.value
        });
    }

    const onSubmitHandler = () => {
        props.onSubmitted(company)
    }
    
    return <>
        <Card header="Company">
            <form>
                <Textbox value={company.name} onChange={handleTextChange} name="name" label="Name"/>
                <Button name="Save" onClick={onSubmitHandler}/>
            </form>
        </Card>
    </>
}